import PdfStrategy from '../../Strategy/PdfStrategy'
import axios from 'axios'

export default class TicketStrategy implements PdfStrategy {
    getUrl(clientName, value) {
        return `${process.env.REACT_APP_SERVER}getticket/${clientName}/${value}`
    }

    getTicket(clientName, value: string) {
        return axios.get(this.getUrl(clientName, value), {
            responseType: 'blob',
        })
    }
}