import PdfStrategy from '../../Strategy/PdfStrategy'
import axios from 'axios'

export default class ArticleStrategy implements PdfStrategy {
  getUrl(clientName, value) {
    return `${process.env.REACT_APP_SERVER}invoice/pdf/${clientName}/${value}`
  }

  getTicket(clientName, value: string) {
    return axios.get(this.getUrl(clientName, value), {
      responseType: 'blob',
    })
  }
}