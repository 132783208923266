import axios from 'axios'
import PdfStrategy from '../../Strategy/PdfStrategy'

export default class CartTicketStrategy implements PdfStrategy {
    
    getUrl(clientName, value) {
        return `${process.env.REACT_APP_SERVER}getcart/${clientName}/${value}`
    }
    getTicket(clientName, value: string) {
        return axios.get(this.getUrl(clientName, value), {
            responseType: 'blob',
        })
    }
}