import React, {ChangeEvent, useState, useEffect} from 'react'
import './App.css'
import {Button, Card, CardBody, Form, FormGroup, Label, Input, Col, Row, FormFeedback, FormText} from 'reactstrap'
import download from 'downloadjs'
import PDFViewer from 'pdf-viewer-reactjs'
import queryString from 'query-string'
import axios from 'axios'
import {Lengths} from './Util/Lengths'
import CartTicketStrategy from './Modules/Cart/CartTicketStrategy'
import PdfTicketContext from './Context/PdfTicketContext'
import TicketStrategy from './Modules/Ticket/TicketStrategy'
import ArticleStrategy from './Modules/Article/ArticleStrategy'

function App() {
    const parsed = queryString.parse(window.location.search)
    const {client: clientParsed, numero: numeroParsed, cart: cartToken, hide: hideParsed} = parsed

    const hide = hideParsed === 'true'
    
    const [client, setClient] = useState(clientParsed || '')
    const [numero, setNumero] = useState(numeroParsed || '')
    const [data, setData] = useState(null)
    const [isLoaded, setLoaded] = useState(false)

    function descargar() {
        download(data, `${numero}.pdf`, 'application/pdf')
    }
    
    const clientIsArray = Array.isArray(client)
    const numeroIsArray = Array.isArray(numero)
    
    if (clientIsArray) {
        console.log('error: solo se permite un cliente')
        alert('solo se permite un cliente')
    } else if (numeroIsArray) {
        console.log('error: solo se permite un numero')
        alert('solo se permite un numero')
    }

    const ticketContext = new PdfTicketContext()
    let strategy
    if (numero.length === Lengths.cart) {
        strategy = new CartTicketStrategy()
    }else if (numero.length === Lengths.article) {
        strategy = new ArticleStrategy()
    } else {
        strategy = new TicketStrategy()
    }
    
    const handleSend = (event: React.MouseEvent<any, MouseEvent>) => {
        event.preventDefault()
        if (!client || !numero) {
            console.log('no client o no numero')
            return
        }
        if (Array.isArray(client) || Array.isArray(numero)) {
            return
        }
        ticketContext.execute(strategy, client, numero).then(response => {
            console.log('handleSend - response: ', response)
            setData(response.data)
        })
    }

    const handleChangecliente = (event: ChangeEvent<HTMLInputElement>) => {
        setData(null)
        setClient(event.target.value)
    }
    const handleChangeQR = (event: ChangeEvent<HTMLInputElement>) => {
        setData(null)
        setNumero(event.target.value)
        console.log('handleChange - numero: ', numero)

    }
    
    const url = strategy?.getUrl(client, numero)
    
    useEffect(() => {
       
        if (client && numero && !isLoaded) {
            axios.get(url, {
                responseType: 'blob',
            })
                .then(response => {
                    setLoaded(true)
                    setData(response.data)
                })
                .catch(reason => {
                    console.log('error: ', reason)
                    setLoaded(true)
                })
        }
    })
    
    return (
        <div>
            <Card>
                <CardBody>
                    <Form>
                        {!hide && !Array.isArray(numeroParsed) && <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="client">cliente</Label>
                                    <Input type="text" name="client" id="client" placeholder="cliente"
                                           onChange={handleChangecliente} value={client}/>
                                    <FormFeedback>Oh noes!</FormFeedback>
                                    <FormText>Introduzca el nombre del prestador del servicio</FormText>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label
                                      for="numero">{`${cartToken ? 'numero del carrito' : 'número del ticket'}`}</Label>
                                    <Input type="text" name="numero" id="numero" placeholder="QR"
                                           onChange={handleChangeQR} value={cartToken ?? numero}/>
                                    <FormFeedback>Oh no</FormFeedback>
                                    <FormText>Introduzca el número del QR</FormText>
                                </FormGroup>
                            </Col>
                        </Row>}
                        {!hide && <Button onClick={handleSend}>Buscar</Button>}
                        {data && <Button className="float-right" onClick={descargar}>Descargar</Button>}
                    </Form>
                </CardBody>
                {data && <PDFViewer
                    /*watermark={{
                        text: 'powered by ticando',
                        diagonal: true,
                        opacity: '0.3',
                        font: 'Comic Sans MS',
                        size: '25',
                        color: '#f10a0a'
                    }}*/
                    hideNavbar
                    document={{
                        url
                    }}
                />}
                {/*{data &&
                    <ViewPDF url={`https://api.crea-ticket.com/api_dev/v2/getticket/${cliente}/${numero}`}
                />}*/}
            </Card>
        </div>
    );
}

export default App;
